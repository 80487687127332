







import { Vue, Component, Provide } from "vue-property-decorator";
@Component({})
export default class Nav extends Vue {
    @Provide() path = this.$store.state.headerPath

  goBack(): void {
    this.$router.go(-1);
  }
}
